<script>
import { required, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import axios from 'axios';
import {  Hooper,  Slide,  Pagination as HooperPagination  } from 'hooper';
import 'hooper/dist/hooper.css';
import TOS from './tos';

export default {
  components: { Hooper, Slide, HooperPagination, TOS},
  data() {
    return {
      componentKey: 0,
      where:        "",
      purposes:     "",
      improvement:  "",

      user: {
        nm_name:          "",
        nm_lastName:      "",
        de_email:         "",
        num_phoneNumber:  "",
        de_password:      ""
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false
    };
  },
  computed: {  },
  watch:{},
  validations: {
    user: {
      nm_name:          { required },
      nm_lastName:      { required },
      de_email:         { required, email },
      num_phoneNumber:  { required },
      de_password:      { required }
    },
    where:        { required },
    purposes:     { required },
    improvement:  { required },
  },
  mounted(){},
  methods: {
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let app = this;

        Swal.fire({
          title: "Creating account, please wait.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        this.user.where = this.where;
        this.user.purposes = this.purposes;
        this.user.improvement = this.improvement;
        
        axios
        .post(
          window.serverPath+'dist/mvw/controllers/users.php',
          {
            request: 'saveUser',
            data: this.user
          }
        )
        .then(response => {
          console.log(response);
          if( response.status == 200 ){
            if(response.data.user){
              app.user          = response.data;
              this.user         = app.user;
              localStorage.setItem( 'user', JSON.stringify(this.user) );
              localStorage.setItem( 'anytimeLaunched', true );

              Swal.close();
              app.$router.push('/').catch((e) => e);
            }else{
              Swal.fire("Ooops!", "🤨 " + response.data.error + " <br>Please try again.", "error");
            }
          }else{
            Swal.fire("Ooops!", "Something is wrong with your credential🤨! Please try again.", "error");
          }
        })
        .catch(function (error) {
          if(
            error.code == "ERR_BAD_RESPONSE"    ||
            error.code == "ERR_NETWORK"
          ){
            Swal.fire("Ooops!", "Something is wrong with our service 😅!<br>Please try again.", "error");
          }
        })
      }
    },
  }
};
</script>

<template>
  <div class="row" style="background-color: #ffffff;">
    <div class="row container-fluid justify-content-center">
      <div class="col-sm-12 no-gutters align-items-center">
        <div class="text-center pt-5">
          <img src="@/assets/images/logo.png" height="50" alt="BlueSeal" />
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <hooper :centerMode="true">
        <slide>
          <div class="row">
            <div class="col-sm-12 mt-5 p-5">
              <h1 class="mb-5">Welcome to BlueSeal</h1>
              <h3 class="mt-5 mb-5">Before you continue using our app, we’d love to learn a bit more about you!</h3>
              <p class="mt-5">By the end of this process, you’ll have the opportunity to create your account and fully enjoy all the amazing features our app has to offer.</p>
            </div>
          </div>
        </slide>

        <!-- WHERE -->
        <slide>
          <div class="row">
            <div class="col-sm-12 mt-5 p-5">
              <h2>Where did you hear or read about us?</h2>
            </div>
          </div>
          <div class="row p-5">
            <div class="col-sm-6">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="where"
                  id="google"
                  value="Google"
                  v-model="where"
                />
                <label class="form-check-label" for="google">Google</label>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="where"
                  id="facebook"
                  value="Facebook"
                  v-model="where"
                />
                <label class="form-check-label" for="facebook">Facebook</label>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="where"
                  id="linkedin"
                  value="LinkedIn"
                  v-model="where"
                />
                <label class="form-check-label" for="linkedin">LinkedIn</label>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="where"
                  id="friend"
                  value="Friend Recommendation"
                  v-model="where"
                />
                <label class="form-check-label" for="friend">A Friend Recommendation</label>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="where"
                  id="email"
                  value="Email"
                  v-model="where"
                />
                <label class="form-check-label" for="email">Email</label>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="where"
                  id="other"
                  value="Other media"
                  v-model="where"
                />
                <label class="form-check-label" for="other">Other media</label>
              </div>
            </div>
          </div>
        </slide>

        <!-- PURPOSES -->
        <slide>
          <div class="row">
            <div class="col-sm-12 mt-5 p-5">
              <h2 style="color: white;">How do you plan to use our application?</h2>
            </div>
          </div>
          <div class="row p-5">
            <div class="col-sm-4">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="purposes"
                  id="commercial"
                  value="Commercial"
                  v-model="purposes"
                />
                <label class="form-check-label" for="commercial">For Commercial purposes (business, sales, etc.)</label>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="purposes"
                  id="personal"
                  value="Personal"
                  v-model="purposes"
                />
                <label class="form-check-label" for="personal">For Personal purposes (hobbies, lifestyle, etc.)</label>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="purposes"
                  id="education"
                  value="Education"
                  v-model="purposes"
                />
                <label class="form-check-label" for="education">For Education purposes (learning, teaching, etc.)</label>
              </div>
            </div>
          </div>
        </slide>

        <!-- IMPROVEMENT -->
        <slide>
          <div class="row">
            <div class="col-sm-12 mt-5 p-5">
              <h2 style="color: white;">What aspects would you like to enhance while using our app?</h2>
            </div>
          </div>
          <div class="row p-5">
            <div class="col-sm-4">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="improvement"
                  id="performance"
                  value="performance and speed"
                  v-model="improvement"
                />
                <label class="form-check-label" for="performance">Performance and speed.</label>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="improvement"
                  id="management"
                  value="Team management"
                  v-model="improvement"
                />
                <label class="form-check-label" for="management">Team management.</label>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="improvement"
                  id="reporting"
                  value="Reporting and analytics capabilities"
                  v-model="improvement"
                />
                <label class="form-check-label" for="reporting">Reporting and analytics capabilities.</label>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="improvement"
                  id="sales"
                  value="Analytics for identifying top-performing products and services"
                  v-model="improvement"
                />
                <label class="form-check-label" for="sales">Analytics for identifying top-performing products and services.</label>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="improvement"
                  id="access"
                  value="Real-time data insights for informed decision-making"
                  v-model="improvement"
                />
                <label class="form-check-label" for="access">Real-time data insights for informed decision-making.</label>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-check mb-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="improvement"
                  id="others"
                  value="Other"
                  v-model="improvement"
                />
                <label class="form-check-label" for="others">Others.</label>
              </div>
            </div>
          </div>
        </slide>

        <slide>
          <div class="row">
            <div class="col-sm-12 mt-5 p-5">
              <div class="">
                <b-alert
                  v-model="registerSuccess"
                  class="mt-3"
                  variant="success"
                  dismissible
                >Registration successfull.</b-alert>

                <b-alert
                  v-model="isRegisterError"
                  class="mt-3"
                  variant="danger"
                  dismissible
                >{{regError}}</b-alert>
                
                <form class="form-horizontal" @submit.prevent="tryToRegisterIn">
                  <div class="form-group auth-form-group-custom">
                    <i class="ri-user-2-line auti-custom-input-icon"></i>
                    <label for="nm_name">Name</label>
                    <input
                      v-model="user.nm_name"
                      type="text"
                      class="form-control"
                      id="nm_name"
                      :class="{ 'is-invalid': submitted && $v.user.nm_name.$error }"
                      placeholder="Enter your name"
                    />
                    <div
                      v-if="submitted && !$v.user.nm_name.required"
                      class="invalid-feedback"
                    >Name is required.</div>
                  </div>

                  <div class="form-group auth-form-group-custom">
                    <i class="ri-user-2-line auti-custom-input-icon"></i>
                    <label for="nm_lastName">Last name</label>
                    <input
                      v-model="user.nm_lastName"
                      type="text"
                      class="form-control"
                      id="nm_lastName"
                      :class="{ 'is-invalid': submitted && $v.user.nm_lastName.$error }"
                      placeholder="Enter your last name"
                    />
                    <div
                      v-if="submitted && !$v.user.nm_lastName.required"
                      class="invalid-feedback"
                    >Last name is required.</div>
                  </div>

                  <div class="form-group auth-form-group-custom">
                    <i class="ri-mail-line auti-custom-input-icon"></i>
                    <label for="de_email">Email</label>
                    <input
                      v-model="user.de_email"
                      type="email"
                      class="form-control"
                      id="de_email"
                      placeholder="Enter your email"
                      :class="{ 'is-invalid': submitted && $v.user.de_email.$error }"
                    />
                    <div v-if="submitted && $v.user.de_email.$error" class="invalid-feedback">
                      <span v-if="!$v.user.de_email.required">Email is required.</span>
                      <span v-if="!$v.user.de_email.email">Please enter valid email.</span>
                    </div>
                  </div>

                  <div class="form-group auth-form-group-custom">
                    <i class="ri-phone-line auti-custom-input-icon"></i>
                    <label for="num_phoneNumber">Phone number</label>
                    <input
                      v-model="user.num_phoneNumber"
                      type="text"
                      class="form-control"
                      id="num_phoneNumber"
                      :class="{ 'is-invalid': submitted && $v.user.num_phoneNumber.$error }"
                      placeholder="Enter phone number"
                    />
                    <div
                      v-if="submitted && !$v.user.num_phoneNumber.required"
                      class="invalid-feedback"
                    >Phone number is required.</div>
                  </div>

                  <div class="form-group auth-form-group-custom">
                    <i class="ri-lock-2-line auti-custom-input-icon"></i>
                    <label for="de_password">Password</label>
                    <input
                      v-model="user.de_password"
                      type="password"
                      class="form-control"
                      id="de_password"
                      placeholder="Enter password"
                      :class="{ 'is-invalid': submitted && $v.user.de_password.$error }"
                    />
                    <div
                      v-if="submitted && !$v.user.de_password.required"
                      class="invalid-feedback"
                    >Password is required.</div>
                  </div>

                  <div class="text-center">
                    <button
                      class="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                    >Signup</button>
                  </div>

                  <div class="mt-4 text-center">
                    <p class="mb-0">
                      By registering you are agreed with the Blue Seal
                      <a
                        href="#"
                        class="text-primary"
                        v-b-modal.modal-tos
                      >TOS</a>
                    </p>
                    <p>
                      Already have an account ?
                      <router-link
                        tag="a"
                        to="/login"
                        class="font-weight-medium text-primary"
                      >Login</router-link>
                    </p>
                  </div>
                  <b-modal
                    no-close-on-backdrop
                    id="modal-tos"
                    title="TERM OF SERVICE"
                    title-class="font-20"
                    hide-footer
                    scrollable
                  >
                    <TOS></TOS>
                  </b-modal>
                </form>
              </div>
            </div>
          </div>
        </slide>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
    </div>
  </div>
</template>
<style type="text/css">
  body{
    background-color: #ffffff;
  }
  .row h1, h2, h3, p, label{
     color: #192a56 !important;
  }
  p, label{
    /*font-size: 20px;*/
  }
</style>